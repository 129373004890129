import React from "react";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";
import PropTypes from "prop-types";

const PageBreadcrumb = ({ title, crumbLabel, location, pageContext }) => {
    const {
        breadcrumb: { crumbs },
    } = pageContext;
    const customCrumbLabel = location.pathname.toLowerCase();
    const crumbLabelArr = customCrumbLabel.split("/");
    const label = crumbLabelArr[crumbLabelArr.length - 1];
    const labelArr = label.split("-");
    const disableLinks = [
        "/games",
        "/category",
        "/profile",
        "/date",
        "/tag",
        "/page",
        "/blog",
        "/blog/page",
        "/blogs",
        "/service",
    ];
    return (
        <section className="breadcrumb-wrap relative bg-cover bg-bottom py-10 lg:py-20">
            <div className="container-full mt-10 transform">
                <Breadcrumb
                    crumbs={crumbs}
                    title={title}
                    crumbLabel={labelArr.join(" ")}
                    disableLinks={disableLinks}
                    className="text-black"
                />
                <span className="hidden breadcrumb__title breadcrumb__list breadcrumb__separator breadcrumb__list__item breadcrumb__link__active"></span>
            </div>
        </section>
    );
};
PageBreadcrumb.propTypes = {
    title: PropTypes.string,
    crumbLabel: PropTypes.string,
    location: PropTypes.object,
    pageContext: PropTypes.object,
};

export default PageBreadcrumb;
